<template>
  <div class="user" style="height: 100%;">
    <div class="boxCol2-2 flex" style="height: 100%;">
      <div class="map-left-side">
        <div class="view-tab">
          <button @click="changeTab(1)" :class="{ active: screenVisible === 1 }">홈</button>
          <button @click="changeTab(2)" :class="{ active: screenVisible === 2 }">4분할</button>
          <button @click="changeTab(3)" :class="{ active: screenVisible === 3 }">9분할</button>
        </div>
        <!-- 전체현장 현황 -->
        <div class="box flex infoCount" style="display: flex;flex-direction: column;">
          <h2>현장 운영 현황</h2>
          <div class="cntIn">
            <div class="cntIn-img">
              <img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" />
            </div>
            <b v-if="accountId=='komipo'"> 로봇운행횟수</b>
            <b v-else > {{ $t("overall-data-cnt-log-drone") }}</b>
            <span>{{ deviceLogCount  }}</span>
          </div>
          <div class="cntIn">
            <div class="cntIn-img"><img src="@/assets/images/icon_svg/vod_icon.png" alt="icon" /></div>
            <b> {{ $t("top-select-total-view-flight-vod") }}</b>
            <span>{{ vodCount }}</span>
          </div>
          <div class="cntIn">
            <div class="cntIn-img"><img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" /></div>
            <b> {{ $t("overall-data-cnt-log-member") }}</b>
            <span>{{ memberCount }}</span>
          </div>
        </div>
        <!-- 현황 컨텐츠 -->
        <div class="monitoring-content">
          <!-- 라이브 현황 -->
          <div class="map-live-count" :class="{'div-3': true, 'div-2': !showThirdDiv}">
            <div class="live-count-title">
              <h2>실시간 라이브</h2>
              <div class="live-switch">
                <span class="switch-title">자동설정</span>
                <label class="switch">
                  <input type="checkbox" v-model="isChecked">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="live-count-list" v-if="channelList.length > 0">
              <div class="live-count-box"  v-for="(data, i) in channelList" :key="'channelList'+i" :class="{ active: selectData != null && selectData.selectId == data.selectId }" @click="handleSelectEl(data)">
                <div class="live-count-info">
                  <h4>{{ data.assignedDeviceName == undefined || data.assignedDeviceName == null ? data.assignedDeviceType :   data.assignedDeviceName }}
                    <span v-if="getViewlChannelByChannel(data) != null">{{'CH'+ (getViewlChannelByChannel(data)+1)}}</span>
                  </h4>
                  <div class="live-count-userInfo">
                    <span>{{ data.assignedDepartmentName }}│</span>
                    <span>{{ data.assignedUserName }}</span>
                  </div>
                </div>
                <div class="live-count-checkbox">
                  <input  v-if="!isChecked" type="checkbox" class="checkbox" @change="channelChange($event , data)" v-model="channelStreamIds" :value="checkStreamId(data)" />
                </div>
              </div>
            </div>
            <div class="live-count-none" v-else>
              <img src="@/assets/images/drone_no_data.png">
              <h4>데이터 없음</h4>
            </div>
          </div>

          <!-- 스테이션 정보 -->
          <div class="map-station div-3" v-if="showThirdDiv">
            <h2>스테이션 정보</h2>
            <div class="map-station-list">
              <div class="map-station-list-box">
                <div class="station-name">
                  <h4>1번 스테이션</h4>
                  <p>M3T</p>
                </div>
              </div>
            </div>
            <div class="live-count-none" style="display: none;">
              <img src="@/assets/images/drone_no_data.png">
              <h4>데이터 없음</h4>
            </div>
          </div>

          <!-- 공지사항 정보 -->
          <div class="map-notice" :class="{'div-3': true, 'div-2': !showThirdDiv}">
            <h2>공지사항</h2>
            <div class="map-notice-list">
              <div v-for="(data, i) in noticeList" :key="'noticeList_'+i" >
                <div class="map-notice-list-box" v-if="data.deletedDate == null">
                  <div class="map-notice-comment">
                    <h2>{{ data.noticeContent }}</h2>
                    <h4>{{ moment(data.registerDate).format("YYYY-MM-DD HH:mm") }}</h4>
                  </div>
                  <div class="map-notice-img" v-if ="data.deletedDate == null && data.isImage == true" :style="'background-image: url('+ getNoticeImageUrl(data) +')'">
                    <img src="@/assets/images/icon_svg/ic_zoom_in_line.svg" alt="" @click="popImg(data)"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- map tab -->
      <div class="monitoring-teb" v-show="screenVisible === 1">
        <div ref="map_div" class="map" style="border-top: 1px solid #919191; height:calc(100% - 50px);margin-top:50px;">
          <Map 
            ref="map" 
            style="width: 100%; height: 100%; min-width: 767px;" 
            :loadMapName="loadMap" 
            @item-click="onMapItemClick"
            @playerRemoveId="playerRemove"/>
          
          <!-- 지도버튼모달 -->
          <MapButtonComponent ref="buttonMap" class="monitor_map" />
            
        </div>
        <div class="flex tabContent" ref="tab_div" style="width: min-content;">
          <transition name="screen">
            <!--live 영상 3개 표출-->
            <div class="liveStatus3" v-if="isTabActive" :style="[isTabActive ? {'visibility' : 'visible'} : {'display' : 'none'},]" style="height: calc(100% - 60px);">
              <!-- new video box 3 view 20240126-->
              <div  v-for="(index) in 3" :key="'screenVisible_3_'+index" style="height: 290px; margin-bottom:8px;" @click="handleSelectEl(getLiveData(index - 1))" >
                <div class="vodview">
                  <div class="video-box"> <!--영상 송출 유무 표현-->
                    <span class="channel" style="z-index:101; width:50px; height:27px; text-align:center; line-height: 20px; position: absolute; top: 10px; right: 10px; background: #3c3c3ccc; color:white; font-weight: 400; font-size:1.4rem; border-radius: 5px; padding-top: 4px;" >CH.{{ index }}</span>


<!--                    streamServer.properties.sdpUrl-->
<!--                    streamServer.properties.application-->
<!--                    streamKey-->

                    <WebRTC_WOWZA
                        class="play-on play-3-view"
                        v-if="getLiveData(index - 1) != undefined"
                        v-show="getLiveData(index - 1).isLoad == true"
                        :ref="`video_${getLiveData(index - 1).channelId}`"
                        :sdpUrl="getLiveData(index - 1).streamServer.properties.sdpUrl"
                        :applicationName="getLiveData(index - 1).streamServer.properties.application"
                        :streamName="getLiveData(index - 1).streamKey"
                        :playerId="getLiveData(index - 1).channelId"
                        :selectStreamId ="selectedSoundId"
                        :channelIndex="index"
                        :deviceType="getLiveData(index - 1).assignedDeviceType"
                        @toggleSound ="toggleSound"
                        @isLoadedmetadata="playerLoadData"
                        @playerOpen="playerOpen"
                        @playerRemoveId="playerRemove"
                    />
<!--                    <Video-->
<!--                      :ref="`video_${getLiveData(index - 1).channelId}`"-->
<!--                      v-if="getLiveData(index - 1) != undefined" v-show="getLiveData(index - 1).isLoad == true"-->
<!--                      class="play-on play-3-view" :src="getLiveUrl(getLiveData(index - 1))"-->
<!--                      :isVod="false"-->
<!--                      :isAuto="true"-->
<!--                      :isControls="false"-->
<!--                      :playerId="getLiveData(index - 1).channelId"-->
<!--                      :selectStreamId ="selectedSoundId"-->
<!--                      :channelIndex="index"-->
<!--                      :deviceType="getLiveData(index - 1).assignedDeviceType"-->
<!--                      @toggleSound ="toggleSound"-->
<!--                      @isLoadedmetadata="playerLoadData"-->
<!--                      @playerOpen="playerOpen" />-->

                    <!-- <a v-if="getLiveData(index - 1) != undefined && getLiveData(index- 1).isLoad == true" style="bottom:10px; right: 10px; line-height: 28px; position: absolute; z-index: 10;" @click="playerOpen('live', getLiveData(index-1).channelId , index)">
                      <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
                    </a> -->
                    <!-- 디자인 변경 시 사용 (스테이션 정보) -->
                    <!-- <div class="video-station-mark" v-if="getLiveData(index - 1) != undefined && getLiveData(index- 1).isLoad == true">
                      <img src="@/assets/images/top_menu_icon_b4.png">
                      <span>스테이션</span>
                    </div> -->

                    <!-- 스피커 연결 시 해제 -->
                    <!-- <a v-if="getLiveData(index - 1) != undefined && getLiveData(index- 1).isLoad == true" class="speaker-btn" @click="toggleSpeaker" style="bottom:10px; right: 45px; line-height: 28px; position: absolute; z-index: 10;">
                      <img v-if="isSpeakerOn" src="@/assets/images/icon_svg/ic_speaker_on.png" alt="icon" />
                      <img v-else src="@/assets/images/icon_svg/ic_speaker_off.png" alt="icon" />
                    </a> -->
                    <div v-show="getLiveData(index- 1) == undefined || getLiveData(index- 1).isLoad == false" class="play-off">
                      <img src="@/assets/images/play-off-icon.png" alt="icon" />
                      <h2>영상 신호 없음</h2>
                    </div>
                  </div>
                  <div class="video-info">
                    <div class="video-top">
                      <!--종류에 따른 이미지 제어-->
                      <div class="emphysema-img">
                        <div class="drone-image" v-if="getLiveData(index - 1) != undefined && getLiveData(index - 1).assignedDeviceType != 'Mobile'&& getLiveData(index- 1).isLoad == true">
                          <img :src="getDeviceImg(getLiveData(index - 1))" alt="icon" />
                        </div>
                        <div class="phone-image" v-if="getLiveData(index - 1) != undefined && getLiveData(index - 1).assignedDeviceType === 'Mobile'&& getLiveData(index- 1).isLoad == true">
                          <img src="@/assets/images/phone-img.png" alt="icon" />
                        </div>
                      </div>
                      <div class="battery-info">
                        <h4>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index -1).battery== undefined ? ' - ' : getLiveData(index -1).battery}}</span>
                          <span>%</span>
                        </h4>
                        <ul>
                          <li v-for="(b_index) in 5" :key="'battery' + index + b_index"  :class=" getLiveData(index -1) == undefined || getLiveData(index -1).isLoad == false || getLiveData(index -1).battery ==undefined  ? 'off' : (5-b_index)*20 <= getLiveData(index - 1).battery ? 'on' : 'off' " />
                        </ul>
                      </div>
                    </div>
                    <div class="drone-video-info">
                      <div class="video-title" :class="{'default-padding': showFourthDiv, 'changed-padding': !showFourthDiv}">
                        <h2>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' 디바이스 ' : ' 디바이스 ' }}</span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedDeviceName : getLiveData(index - 1).assignedDeviceType}}</span>
                        </h2>
                        <h4>
                          <span>{{$t("device-data-manager-dept-name")}} </span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDepartmentName}}</span>
                        </h4>
                        <h4>
                          <span>조종자 </span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedUserName : getLiveData(index - 1).assignedUserName }}</span>
                        </h4>
                      </div>
                      <div class="video-value" :class="{'default-padding': showFourthDiv, 'changed-padding': !showFourthDiv}">
                        <div class="value-box">
                          <h4>고도</h4>
                          <h2><span class="dataValue">{{ getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index - 1).height == null ? '-' : `${getLiveData(index - 1).height.toFixed(0)} ` }}</span>m</h2>
                        </div>
                        <div class="value-box">
                          <h4>거리</h4>
                          <h2><span class="dataValue">{{ getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index - 1).distance == null ? '-' : `${getLiveData(index - 1).distance.toFixed(0)} ` }} </span>m</h2>
                        </div>
                        <div class="value-box">
                          <h4>수평</h4>
                          <h2><span class="dataValue">{{ getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index - 1).hspeed == null ? '-' : `${getLiveData(index - 1).hspeed.toFixed(0)} ` }}</span>m/s</h2>
                        </div>
                        <div class="value-box">
                          <h4>수직</h4>
                          <h2><span class="dataValue">{{ getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index - 1).vspeed == null ? '-' : `${getLiveData(index - 1).vspeed.toFixed(0)} ` }}</span>m/s</h2>
                        </div>
                      </div>
                      <div class="video-coordinate" :class="{'default-padding': showFourthDiv, 'changed-padding': !showFourthDiv}">
                        <div class="coordinate-row">
                          <h4>위도</h4>
                          <h2><span class="dataValue">{{ getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index - 1).lat == null ? '-' : `${getLiveData(index - 1).lat.toFixed(9)} ` }} </span></h2>
                        </div>
                        <div class="coordinate-row">
                          <h4>경도</h4>
                          <h2><span class="dataValue">{{ getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false || getLiveData(index - 1).lng == null ? '-' : `${getLiveData(index - 1).lng.toFixed(9)} ` }} </span></h2>
                        </div>
                      </div>
                      <div class="video-station-info" v-if="showFourthDiv">
                        <div class="station-on">
                          <h4>스테이션 모델</h4>
                          <h2>1번 스테이션</h2>
                        </div>
                        <div class="station-off"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="monitoring-teb" v-if="screenVisible === 2">
        <div class="multi-view">
          <transition name="screen">
            <!--live 영상 4개 표출-->

            <div class="liveStatus4" v-if="isTabActive" :style="[isTabActive ? {'visibility' : 'visible'} : {'display' : 'none'},]" style="height: 100%; margin-top: 50px;padding:5px 0">
                <!-- new video box 3 view 20240126-->
                <div  v-for="(index) in 4" :key="'screenVisible_4_'+index" class="fourView"  style="width:47%; height: auto; position:relative;">
                <div class="vodview">
                  <div class="video-box" style="height:100%;"> <!--영상 송출 유무 표현-->
                    <span class="channel" style="z-index:101; width:50px; height:27px; text-align:center; line-height: 20px; position: absolute; top: 10px; right: 10px; background: #3c3c3ccc; color:white; font-weight: 400; font-size:1.4rem; border-radius: 5px; padding-top: 4px;" >CH.{{ index }}</span>
                    <div class="video-info-box">
                      <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedDeviceName : getLiveData(index - 1).assignedDeviceType}}</span>
                      <span>│</span>
                      <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedUserName : getLiveData(index - 1).assignedUserName }}</span>
                    </div>

                    <WebRTC_WOWZA
                        class="play-on"
                        v-if="getLiveData(index - 1) != undefined"
                        v-show="getLiveData(index - 1).isLoad == true"
                        :ref="`video_${getLiveData(index - 1).channelId}`"
                        :sdpUrl="getLiveData(index - 1).streamServer.properties.sdpUrl"
                        :applicationName="getLiveData(index - 1).streamServer.properties.application"
                        :streamName="getLiveData(index - 1).streamKey"
                        :playerId="getLiveData(index - 1).channelId"
                        :selectStreamId ="selectedSoundId"
                        :channelIndex="index"
                        :deviceType="getLiveData(index - 1).assignedDeviceType"
                        @toggleSound ="toggleSound"
                        @isLoadedmetadata="playerLoadData"
                        @playerOpen="playerOpen"
                        @playerRemoveId="playerRemove"
                    />

<!--                    <Video-->
<!--                      :ref="`video_${getLiveData(index - 1).channelId}`"-->
<!--                      v-if="getLiveData(index - 1) != undefined" v-show="getLiveData(index - 1).isLoad == true"-->
<!--                      class="play-on" :src="getLiveUrl(getLiveData(index - 1))"-->
<!--                      :isVod="false"-->
<!--                      :isAuto="true"-->
<!--                      :isControls="false"-->
<!--                      :playerId="getLiveData(index - 1).channelId"-->
<!--                      :selectStreamId ="selectedSoundId"-->
<!--                      @toggleSound ="toggleSound"-->
<!--                      @isLoadedmetadata="playerLoadData" />-->

                    <!-- <a v-if="getLiveData(index - 1) != undefined && getLiveData(index- 1).isLoad == true" style="bottom:30px; right: 10px; line-height: 28px; position: absolute; z-index: 10;" @click="playerOpen('live', getLiveData(index-1).channelId , index)">
                      <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
                    </a> -->

                    <div v-show="getLiveData(index- 1) == undefined || getLiveData(index- 1).isLoad == false" class="play-off-4">
                      <img src="@/assets/images/play-off-icon.png" alt="icon" />
                      <h2>영상 신호 없음</h2>
                    </div>
                  </div>
                  <div class="video-info view4-info" style="background: #232323cc;">
                    <div class="drone-video-info">
                      <div class="video-title">
                        <h2>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' 디바이스 ' : ' 디바이스 ' }}</span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedDeviceName : getLiveData(index - 1).assignedDeviceType}}</span>
                        </h2>
                        <h4>
                          <span>{{$t("device-data-manager-dept-name")}} </span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDepartmentName}}</span>
                        </h4>
                        <h4>
                          <span>조종자 </span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedUserName : getLiveData(index - 1).assignedUserName }}</span>
                        </h4>
                        <h4>
                          <span>스테이션 </span>
                          <span>-</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="monitoring-teb" v-if="screenVisible === 3">
        <div class="multi-view">
          <transition name="screen">
            <!--live 영상 9개 표출-->
            <div class="liveStatus9" v-if="isTabActive" :style="[isTabActive ? {'visibility' : 'visible'} : {'display' : 'none'},]" style="height: 100%; margin-top: 50px;padding: 5px 0;">
              <!-- new video box 3 view 20240126-->
              <div  v-for="(index) in 9" :key="'screenVisible_9_'+index" class="fourView"  style="width:31.3333%; height: auto;position:relative;">
                <div class="vodview">
                  <div class="video-box" style="height:100%;"> <!--영상 송출 유무 표현-->
                    <span class="channel" style="z-index:101; width:50px; height:27px; text-align:center; line-height: 20px; position: absolute; top: 10px; right: 10px; background: #3c3c3ccc; color:white; font-weight: 400; font-size:1.4rem; border-radius: 5px; padding-top: 4px;" >CH.{{ index }}</span>
                    <div class="video-info-box">
                      <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedDeviceName : getLiveData(index - 1).assignedDeviceType}}</span>
                      <span>│</span>
                      <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedUserName : getLiveData(index - 1).assignedUserName }}</span>
                    </div>

                    <WebRTC_WOWZA
                        class="play-on"
                        v-if="getLiveData(index - 1) != undefined"
                        v-show="getLiveData(index - 1).isLoad == true"
                        :ref="`video_${getLiveData(index - 1).channelId}`"
                        :sdpUrl="getLiveData(index - 1).streamServer.properties.sdpUrl"
                        :applicationName="getLiveData(index - 1).streamServer.properties.application"
                        :streamName="getLiveData(index - 1).streamKey"
                        :playerId="getLiveData(index - 1).channelId"
                        :selectStreamId ="selectedSoundId"
                        :channelIndex="index"
                        :deviceType="getLiveData(index - 1).assignedDeviceType"
                        @toggleSound ="toggleSound"
                        @isLoadedmetadata="playerLoadData"
                        @playerOpen="playerOpen"
                        @playerRemoveId="playerRemove"
                    />

<!--                    <Video-->
<!--                      :ref="`video_${getLiveData(index - 1).channelId}`"-->
<!--                      v-if="getLiveData(index - 1) != undefined" v-show="getLiveData(index - 1).isLoad == true"-->
<!--                      class="play-on" :src="getLiveUrl(getLiveData(index - 1))"-->
<!--                      :isVod="false"-->
<!--                      :isAuto="true"-->
<!--                      :isControls="false"-->
<!--                      :playerId="getLiveData(index - 1).channelId"-->
<!--                      :selectStreamId ="selectedSoundId"-->
<!--                      @toggleSound ="toggleSound"-->
<!--                      @isLoadedmetadata="playerLoadData"-->
<!--                      @playerRemoveId="playerRemove"/>-->

                    <!-- <a v-if="getLiveData(index - 1) != undefined && getLiveData(index- 1).isLoad == true" style="bottom:30px; right: 10px; line-height: 28px; position: absolute; z-index: 10;" @click="playerOpen('live', getLiveData(index-1).channelId , index)">
                      <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
                    </a> -->

                    <div v-show="getLiveData(index- 1) == undefined || getLiveData(index- 1).isLoad == false" class="play-off-9">
                      <img src="@/assets/images/play-off-icon.png" alt="icon" />
                      <h2>영상 신호 없음</h2>
                    </div>
                  </div>
                  <div class="video-info view9-info" style="background: #232323cc;">
                    <div class="drone-video-info">
                      <div class="video-title">
                        <h2>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' 디바이스 ' : ' 디바이스 ' }}</span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedDeviceName : getLiveData(index - 1).assignedDeviceType}}</span>
                        </h2>
                        <h4>
                          <span>{{$t("device-data-manager-dept-name")}} </span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDepartmentName}}</span>
                        </h4>
                        <h4>
                          <span>조종자 </span>
                          <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? ' - ' : getLiveData(index - 1).assignedDeviceType != 'Mobile' ? getLiveData(index - 1).assignedUserName : getLiveData(index - 1).assignedUserName }}</span>
                        </h4>
                        <h4>
                          <span>스테이션 </span>
                          <span>-</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.main{height:100%;}
.lnb{display: none;}
.map-left-side{width: 280px;
    height: calc(100% - 50px);
    z-index: 1;
    background: #232323;
    position: absolute;
    bottom: 0;
    left: 0;}
.gnb{display: flex;}
.bar-system-title {
    font-size: 1.8rem;
    margin-right: 15px;
    margin-bottom: 0;
    font-weight: bold;
    color: #fff;
    letter-spacing: -1px;
}
.monitoring-teb{width: 100%;}
.monitoring-content{height: calc(100% - 214px);}

.scheduler-box{position: absolute;left: 60px;top: 15px;background: rgba(16, 16, 16, 0.82);width: 991px;border-radius: 5px;padding: 8px 15px;display: flex;align-items: center;justify-content: space-between;}
.scheduler-box > .scheduler-info{display: flex;align-items: center;}
.scheduler-box > .scheduler-info > .scheduler-number{background: #0080FF;border-radius: 5px;padding: 2px 15px;}
.scheduler-box > .scheduler-info > .scheduler-number > h2{font-size: 1.2rem; margin: 0;color: #fff;}
.scheduler-box > .scheduler-info > .scheduler-number > h2 > span{font-size: 1.2rem;}
.scheduler-box > .scheduler-info > .scheduler-value{margin-left: 0px;}
.scheduler-box > .scheduler-info > .scheduler-value > ul{display: flex;align-items: center;}
.scheduler-box > .scheduler-info > .scheduler-value > ul > li{color: #fff;font-size: 12px;border-right: 1px solid #fff;padding: 0 10px;}
.scheduler-box > button{background: #4e79ff;font-size: 14px;color: #fff;padding: 2px 15px;border-radius: 5px;border: 0; font-weight: 300;height: auto;}
.scheduler-box > button:hover{cursor: pointer;background: #2854d8;}

.screen-enter /* .fade-leave-active below version 2.1.8 */
{
  transition: opacity 0.2s;
  opacity: 0.2;
  min-width: 0px;
  width: 0%;
}

.screen-enter-active {
  transition: opacity 0.2s;
  min-width: 645px;
  width: 40%;
}

.droneVal2>.droneData>span{
  font-size: 1.1rem;
  font-weight: normal;
  color: #50b1ff;
  /*display: flex;*/
}

.dataValue {
  color: white !important;
  margin-left: 2px !important;
}

.dataVal2 {
  font-size: 1.4rem;
  color: #fff;
  margin-left: 3px;
  font-weight: bold;
}

.btn_station {
  margin: auto;
  justify-content: center;
}

button.ptDel {
  padding: 0px 10px;
  background: #b9b9b9;
}

.visibleHang:hover {
  background: #333;
}
.stLive:nth-child(3) {
  /*width: calc(100% - 0px) !important;*/
  border: 1px solid #333333 !important;
}
.tabtab{
  margin-top: 10px;
}

/* 지도 위 정보 팝업 CSS*/
.lnglatData{
  font-size: 1.2rem;
  color: #0b7dd9;
  margin-right: 5px;
}
.lnglatVal{
  font-size: 1.2rem;
  color: #808080;
}
.contents>p{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  -webkit-line-clamp: 1;
}
.contents>div.wid5050>span{
  /*width: 90px;*/
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  -webkit-line-clamp: 1;
}

/* new css */
.liveStatus3{
  position:absolute;
  right:10px;
  top: 55px;
  z-index:1;
  min-width: 375px;
  background: #33333300;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.logInfo-list::-webkit-scrollbar-thumb {
    background-color: #6e6f72;
    border-radius: 4px;
}
.logInfo-list::-webkit-scrollbar-track {
    border-radius: 2px ;
    background: rgba(40, 43, 53, 0.0);
    border: 0px solid #f0f0f0;
}
.InfoDt {
    position: relative;
    color: #ffffff;
    font-weight: normal;
    padding: 5px 0px 5px 10px;
    background: #3c3c3c;
    margin-bottom: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.InfoDt > div > span{
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.InfoDt > div > img{
  width: 12px;
  margin-right: 10px;
}
.InfoDt > span:nth-child(3){
  position: absolute;
  right: 15px;
}
.vodview{display: flex;height: 100%;background: #232323;margin-bottom: 15px;flex-direction: column;}
.video-box{width: 100%;height: 211px;background: #000000;position: relative;}
.video-box > .play-on{width: 100%;height: 100%;}
.video-box > .play-off{width: 100%;text-align: center;position:absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.video-box > .play-off > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;}
.video-box > .play-off > img{width: 25px;}

.video-box > .play-off-4{width: 100%;text-align: center;padding-top: 50.25%;}
.video-box > .play-off-4 > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;position:absolute;transform: translate(-50%, -50%);top: 52%;left: 50%;}
.video-box > .play-off-4 > img{width: 25px;position:absolute;transform: translate(-50%, -50%);top: 48%;left: 50%;}

.video-box > .play-off-9{width: 100%;text-align: center;padding-top: 50.25%;}
.video-box > .play-off-9 > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;position:absolute;transform: translate(-50%, -50%);top: 52%;left: 50%;}
.video-box > .play-off-9 > img{width: 25px;position:absolute;transform: translate(-50%, -50%);top: 48%;left: 50%;}

.video-info{width:100%;color: #fff;padding: 0px;display: flex;flex-direction: row;justify-content: space-between;border-top: 1px solid #4f4f4f;}
.video-info > .video-top{display: flex;align-items: center;justify-content: space-between; margin-bottom: 0px;background: #232323;flex-direction: column;width: 80px;border-right: 1px solid #4f4f4f;}
.video-info > .video-top > .emphysema-img{width: 70px;background: #232323;border-radius: 5px;}
.video-info > .video-top > .emphysema-img > .drone-image{width: 100%;height: 50px;background: #232323;border-radius: 5px;padding: 0px;text-align: center;display: flex;align-items: center;}
.video-info > .video-top > .emphysema-img > .phone-image{width: 100%;height: 50px;background: #232323;border-radius: 5px;padding: 5px;text-align: center;}
.video-info > .video-top > .emphysema-img > .drone-image > img{width: 100%;}
.video-info > .video-top > .emphysema-img > .phone-image > img{height: 100%;}

.video-info > .video-top > .battery-info{display: flex;align-items: center;margin-bottom: 5px;}
.video-info > .video-top > .battery-info > ul{display: flex;margin-bottom: 0px;}
.video-info > .video-top > .battery-info > ul > li{height: 18px;width: 5px;background: #878787;margin-right: 2px;border-radius: 2px;}
.video-info > .video-top > .battery-info > ul > li.on{background: #1cdfa0;}
.video-info > .video-top > .battery-info > ul > li.off{background: #878787}
.video-info > .video-top > .battery-info > h4{text-align: right;margin-right: 5px;}
.video-info > .video-top > .battery-info > h4 > span{font-size: 1.1rem;font-weight: 400;}

.video-info > .drone-video-info{width: calc(100% - 80px);}

.video-info > .drone-video-info > .video-title{border-bottom: 1px solid #4f4f4f;padding: 3px 0px 3px 10px;display: flex;align-items: center;}
.video-info > .drone-video-info > .video-title > h2{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px; margin-right: 5px;display: flex;}
.video-info > .drone-video-info > .video-title > h2 > span{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px;}
.video-info > .drone-video-info > .video-title > h2 > span:nth-child(1){color: #c9c9c9;margin-right: 5px;}
.video-info > .drone-video-info > .video-title > h2 > span:nth-child(2){display: inline-block;width: 70px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.video-info > .drone-video-info > .video-title > h4{font-size: 1.1rem;font-weight: 400;margin-bottom: 0px;display: flex; margin-right: 5px;}
.video-info > .drone-video-info > .video-title > h4:nth-child(3){margin-bottom: 0;}
.video-info > .drone-video-info > .video-title > h4 > span{font-size: 1.1rem;}
.video-info > .drone-video-info > .video-title > h4 > span:nth-child(1){color: #c9c9c9;margin-right: 5px;}
.video-info > .drone-video-info > .video-title > h4 > span:nth-child(2){display: inline-block;width: 45px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.view4-info > .drone-video-info > .video-title > h2{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px; margin-right: 20px;display: flex;}
.view4-info > .drone-video-info > .video-title > h4{font-size: 1.1rem;font-weight: 400;margin-bottom: 0px;display: flex; margin-right: 20px;}
.view4-info > .drone-video-info > .video-title > h2 > span:nth-child(1){color: #c9c9c9;margin-right: 20px;}
.view4-info > .drone-video-info > .video-title > h2 > span:nth-child(2){display: inline-block;width: auto;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.view4-info > .drone-video-info > .video-title > h4 > span:nth-child(1){color: #c9c9c9;margin-right: 20px;}
.view4-info > .drone-video-info > .video-title > h4 > span:nth-child(2){display: inline-block;width: auto;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.view9-info > .drone-video-info > .video-title > h2{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px; margin-right: 20px;display: flex;}
.view9-info > .drone-video-info > .video-title > h4{font-size: 1.1rem;font-weight: 400;margin-bottom: 0px;display: flex; margin-right: 20px;}
.view9-info > .drone-video-info > .video-title > h2 > span:nth-child(1){color: #c9c9c9;margin-right: 20px;}
.view9-info > .drone-video-info > .video-title > h2 > span:nth-child(2){display: inline-block;width: auto;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.view9-info > .drone-video-info > .video-title > h4 > span:nth-child(1){color: #c9c9c9;margin-right: 20px;}
.view9-info > .drone-video-info > .video-title > h4 > span:nth-child(2){display: inline-block;width: auto;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.video-info > .drone-video-info > .video-value{display: flex;flex-wrap: wrap;align-items: center;padding-top: 3px;padding-left: 10px;margin-bottom: 0px;}
.video-info > .drone-video-info > .video-value > .value-box{width: 25%;display: flex;align-items: center;}
.video-info > .drone-video-info > .video-value > .value-box > h4{font-size: 1.1rem;color: #c9c9c9;margin-bottom: 0px;margin-right: 5px;font-weight: 400;}
.video-info > .drone-video-info > .video-value > .value-box > h4 > span{font-size: 1.1rem;}
.video-info > .drone-video-info > .video-value > .value-box > h2{font-size: 1.1rem;font-weight: 300;margin-bottom: 0px}
.video-info > .drone-video-info > .video-value > .value-box > h2 > span{font-size: 1.2rem;}

.video-info > .drone-video-info > .video-coordinate{padding-left: 10px;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #4f4f4f;padding-bottom: 2px;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row{display: flex;align-items: center;margin-bottom: 0px;width: 50%;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h4{font-size: 1.1rem;color: #c9c9c9;margin-right: 5px; font-weight: 400;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2{margin: 0;font-weight: 300;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2 > span{font-size: 1.2rem;}

/* .map > .kakao-map > .video-add-line-show{right: 709px !important;}
.map > .kakao-map > .video-add-button{right: 700px !important;} */
.monitor_map {
  position: absolute;
  top: 0px;
  width: 100%;
  height: calc(100vh - 51px);
  pointer-events: none;
}
.monitor_map > .map-btn-list  {
  width: 500px;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 650px;
  top: 10px;
}

.monitor_map > .infoAlert {
  position: absolute;
  left: 300px;
  bottom: 30px;
  pointer-events: auto;
}
.line-show{
  margin-top: 6px ;
  margin-right: 10px;
}

.map > .monitor_map > .map-btn-list{cursor: pointer;pointer-events: auto;}

.video-player .video-js video{border-radius: 0;object-fit: cover;}
.video-player{height: 100%;}
.video-player .video-js{height: auto;padding-top: 50.25%;}
.video-coordinate > .coordinate-row:nth-child(1){margin-bottom: 10px;}
.play-3-view > .video-player .video-js {height: auto;padding-top: 56.25%;}

@media (min-height: 1080px){
  .video-info > .video-title > h2 {
    margin-bottom: 10px;
  }
  .video-info > .video-title > h4 {
    margin-bottom: 10px;
  }
  .video-info > .video-title > h4:nth-child(3) {
    margin-bottom: 10px;
  }
  .video-info > .video-value {
    padding-top: 15px;
  }
  .video-coordinate > .coordinate-row:nth-child(1){
    margin-bottom: 10px;
  }
}

/* notice css */
.map-notice{ width: 100%;height: 33.3333%;border-radius: 5px;padding: 10px 15px;}
.map-notice > h2{font-size: 1.4rem;color: #fff;margin-bottom: 10px;margin-top: 3px;}
.map-notice-list{height: calc(100% - 35px);max-height: 100%;overflow-y: scroll;overflow-x: clip;background: #3c3c3c;}
.map-notice-list::-webkit-scrollbar{width: 8px;}
.map-notice-list::-webkit-scrollbar-thumb{background-color: #6e6f72;border-radius: 4px;}
.map-notice-list::-webkit-scrollbar-track{border-radius: 2px;background: rgba(40, 43, 53, 0.0);border: 0px solid #f0f0f0;}
.map-notice-list-box{padding: 15px 5px 15px 10px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #707070;}
.map-notice-comment{width: 75%;padding-right: 5px;}
.map-notice-comment > h2{font-size: 1.2rem;color: #fff;margin-bottom: 3px;word-break: break-all;}
.map-notice-comment > h4{color: #d9d9d9;font-size: 1.2rem;font-weight: 300;}
.map-notice-img{width: 25%;height: 35px;border-radius: 5px;text-align: center; padding-top: 8px}
.map-notice-img > img{height: 70%;width: 70%;border-radius: 5px;}
.map-notice-img > img:hover{cursor: pointer;opacity: 0.8;}

/* live css */
.map-live-count{padding: 10px 15px;width: 100%;height: 33.3333%;border-radius: 5px;border-bottom: 1px solid #4f4f4f;}
.map-live-count > .live-count-title{display: flex;align-items: center;justify-content: space-between;margin-top: 3px;}
.map-live-count > .live-count-title > h2{margin: 0;color: #fff;font-size: 1.4rem;}
.live-count-list{margin-top: 10px;height: calc(100% - 40px);max-height: 100%;overflow-y: scroll;overflow-x: clip;background: #3c3c3c;border-radius: 5px;}
.live-count-list::-webkit-scrollbar{width: 8px;}
.live-count-list::-webkit-scrollbar-thumb{background-color: #6e6f72;border-radius: 4px;}
.live-count-list::-webkit-scrollbar-track{border-radius: 2px;background: rgba(40, 43, 53, 0.0);border: 0px solid #f0f0f0;}
.live-count-list > .live-count-box{position: relative;padding: 10px 5px 10px 10px;display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;border-bottom: 1px solid #707070;}
.live-count-list > .live-count-box > .live-count-info{position: relative;}
.live-count-list > .live-count-box > .live-count-info > h2{font-size: 1.4rem;color: #fff;margin-bottom: 5px;}
.live-count-list > .live-count-box > .live-count-info > h4{color: #ffffff;font-size: 1.4rem;font-weight: 400;margin-bottom: 10px;}
.live-count-list > .live-count-box > .live-count-info > h4 > span{font-size: 1.2rem;background: #232323;padding: 2px 10px;border-radius: 5px;color: #fff;margin-left: 5px;}
.live-count-list > .live-count-box > .live-count-info > .live-count-userInfo{display: flex;align-items: center;}
.live-count-list > .live-count-box > .live-count-info > .live-count-userInfo > span{color: #c9c9c9;font-size: 1.2rem;font-weight: 300;}
.live-count-checkbox{position: absolute;top: 5px;right: 0px;}
.live-count-none{margin-top: 10px;height: calc(100% - 20px);max-height: 100%;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.live-count-none > img{height: 80px;opacity: 0.6;margin-bottom: 15px;}
.live-count-none > h4{font-size: 1.2rem;color: #c9c9c9;font-weight: 300;}

.live-modal {position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;justify-content: center;align-items: center;z-index: 1003;}
.live-modal-content {background-color: #282B35;padding: 20px;border-radius: 8px;width: 1020px;}

.vod-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 1024px;
}
.modal-content-header{display: flex;justify-content: space-between;align-items: center;padding-bottom: 10px;}
.modal-content-header > h2{display: flex;align-items: center;}
.modal-content-header > h2 > .modal-title{color: #05101c;font-size: 1.6rem;margin: 0;}
.modal-content-header > button{border: 0;}
.modal-content-header > button > img{width: 15px;opacity: 0.5;}

.modal-content-body{margin-bottom: 20px;}
.modal-input{display: flex;align-items: center;margin-bottom: 15px;}
.modal-search{margin-right: 10px;display: flex;align-items: center;}
.modal-search > span{color: #969696;background: #eee;padding: 8px 15px;border-radius: 5px;margin-right: 10px;}
.modal-search > select{background: #ffffff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;width: 110px;border: 1px solid #ddd;height: 32px;padding: 5px 25px 5px 12px;border-radius: 5px;font-size: 1.2rem;color: #fff;}
.modal-search > select:focus-visible{outline: 1px solid #2B79EE;}
.modal-search > select > option{background: #ffffff;color:#969696;}
.modal-search > input{margin: 0;background: #ffffff;border: 0;margin-left: 5px;color: #000; border-radius: 5px;border: 1px solid #ddd;}
.modal-search > input:hover{border: 1px solid #ddd;}
.modal-search > input:focus-visible{outline: 1px solid #2B79EE;}

.modal-datepicker{display: flex;align-items: center;margin-right: 10px;}
.modal-datepicker > span{color: #969696;background: #eee;padding: 8px 15px;border-radius: 5px;margin-right: 10px;}
.modal-datepicker > .datepicker-input > input{margin: 0;border: 0;background: #ffffff;border-radius: 3px;color: #000; border-radius: 5px;border: 1px solid #ddd;}

.modal-table{max-height: 400px;height: 400px;overflow-y: scroll;border-bottom: 1px solid #ddd;border-top: 3px solid #7a9aff;}
.modal-table::-webkit-scrollbar-thumb{background-color: #dddddd;border-radius: 4px;}
.modal-table::-webkit-scrollbar-track{border-radius: 2px;background: #f7f7f7;border: 1px solid #f7f7f7;}
.modal-table > table > thead > tr{position: sticky;top: -1px; z-index: 10;}
.modal-table > table > thead > tr > th{background: #efefef;color: #969696;text-align: center;border-bottom: 1px solid #ddd;}
.modal-table > table > tbody > tr:hover{background: #f7f7f7;}
.modal-table > table > tbody > tr > td{border-bottom: 1px solid #ddd;text-align: center;color: #05101c;}
.modal-table > table > tbody > tr > td > select{width: 100px;border-radius: 5px;border: 0;color:#3d321c;  background: #fff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;border: 1px solid #ddd;}
.modal-table > table > tbody > tr > td > select:focus-visible{outline: 1px solid #2B79EE;}
.modal-table > table > tbody > tr > td > select > option{background: #fff; color: #1d1d1d;}
.modal-table > table > tbody > tr > td > select:disabled{background: #eee;}
.modal-table > table > tbody > tr > td > select > option:disabled{background: #cccccc; color: #8b8b8b;}

.modal-content-footer{text-align: center;margin: 30px 0 10px 0;}
.modal-content-footer > button{border: 0;padding: 5px 40px;border-radius: 5px;margin: 0 5px;height: 36px;font-weight: 300;color: #fff;}
.modal-content-footer > button:nth-child(1){background: #2f9eff;color: #fff;}
.modal-content-footer > button:nth-child(2){background: #ffffff;color:#969696;border: 1px solid #ddd;}
.modal-content-footer > button:nth-child(3){background: #ffffff;color:#969696;border: 1px solid #ddd;}

.switch {position: relative; display: inline-block;width: 40px;height: 20px;}
.switch input { opacity: 0; width: 0;height: 0;}
/* 토글 슬라이더 */
.slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #ccc;transition: .4s;}
.slider:before {position: absolute;content: "";height: 18px;width: 18px;left: 2px;bottom: 1px;background-color: white;transition: .4s;}
input:checked + .slider {background-color: #2196F3;}
input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
input:checked + .slider:before {transform: translateX(18px);}
/* 모양 */
.live-switch{display: flex;align-items: center;}
.slider.round {border-radius: 34px;}
.slider.round:before {border-radius: 50%;}
.switch-title{margin-left: 30px;display: inline-block;margin-right: 5px;border-radius: 10px;color: #c9c9c9;font-size: 1.2rem;}


/* 화면 탭 */
.view-tab{width: 100%;display: flex;align-items: center;height: 40px;border-bottom: 1px solid #707070;}
.view-tab > button{height: 100%;border: 0;width: 33.3333%;color: #c9c9c9;font-size: 1.4rem;font-weight: 300;border-radius: 0;}
.view-tab > button:hover{background: #3c3c3c;color: #fff;}
.view-tab > button.active{background: #0080ff;color: #fff !important;}

/* 스테이션 */
.map-station{padding: 10px 15px;width: 100%;height: 33.3333%;border-radius: 5px;border-bottom: 1px solid #4f4f4f;}
.map-station > h2{color: #fff;font-size: 1.4rem;margin-top: 3px;margin-bottom: 10px;}
.map-station > .live-count-none{ height: calc(100% - 35px);max-height: 150px;}

.map-station-list{height: calc(100% - 35px);max-height: 100%;overflow-y: scroll;overflow-x: clip;}
.map-station-list::-webkit-scrollbar{width: 8px;}
.map-station-list::-webkit-scrollbar-thumb{background-color: #6e6f72;border-radius: 4px;}
.map-station-list::-webkit-scrollbar-track{border-radius: 2px;background: rgba(40, 43, 53, 0.0);border: 0px solid #f0f0f0;}
.map-station-list-box{background: #3c3c3c;border-radius: 5px;padding: 5px; display: flex;align-items: flex-start;justify-content: space-between;margin-bottom: 5px;}
.station-name > h4{font-size: 1.2rem;font-weight: 400;color: #fff;margin-bottom: 5px;word-break: break-all;}
.station-name > p{color: #d9d9d9;font-size: 1.2rem;font-weight: 300;}

.station-situation{display: flex;align-items: center;}
.station-situation > h4{font-size: 1.2rem;color: #fff;font-weight: 300;margin-left: 3px;}
.situation-mark{width: 10px;height: 10px;background: #fff;border-radius: 10px;}
.situation-mark.mark-green{background: #32e77a;}
.situation-mark.mark-red{background: #ff5151;}

.video-info-box{position: absolute;top: 10px;left: 50%;transform: translate(-50%, 0%);background: #1010108a;padding: 5px 20px;color: #fff;border-radius: 5px;z-index: 100;}
.video-info-box > span{font-size: 1.4rem;}

.cntIn>span {
    font-size: 1.4rem;
    color: #ffffff;
    font-weight: 400;
    line-height: 3rem;
    margin-left: 15px;
    position: absolute;
    right: 20px;
}
.multi-view{width: calc(100% - 280px);height: calc(100% - 50px);margin-left: 280px;background: #101010;}
.liveStatus4{display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: space-evenly;}
.liveStatus9{display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;justify-content: space-evenly;}
.fourView{border: 1px solid #4f4f4f;}
.fourView > .vodview{border-radius: 0px;margin-bottom: 0;}
.fourView > .vodview > .video-info{height: auto;}
.fourView > .vodview > .video-info > .drone-video-info{width: 100%;}
.fourView > .vodview > .video-info > .drone-video-info > .video-title{border-bottom:0;}

.video-station-info{padding: 3px 10px 3px 10px;background: #232323;}
.video-station-info > .station-on{display: none;width: 100%;justify-content: space-between;align-items: center;}
.video-station-info > .station-on > h4{font-size: 1.2rem;color: #c9c9c9;font-weight: 400;}
.video-station-info > .station-on > h2{font-size: 1.2rem;color: #fff;font-weight: 400;margin: 0;}
.video-station-info > .station-on > .station-mark{display: flex;align-items: center;}
.video-station-info > .station-on > .station-mark > span{width: 10px;height: 10px;background: #32e77a;border-radius: 10px;margin-right: 5px;}
.video-station-info > .station-on > .station-mark > p{font-size: 1.2rem;}
.video-station-info > .station-off{width: 100%;height: 15px;background: #575757;}

.speaker-btn > img{width: 24px;}

.div-3 {
  height: 33.3333%; /* 세 개일 때 높이 */
}
.div-2 {
  height: 50%; /* 두 개일 때 높이 */
}
.changed-padding{
  padding: 5px 5px 5px 5px !important;
}
.changed-padding:nth-child(3){
  border-bottom: 0 !important;
}

</style>

<script>
import icOneScreen from "@/assets/images/icon_svg/ic_one_screen.svg";
import icOneScreenSelect from "@/assets/images/icon_svg/ic_one_screen_select.svg";
import icFourScreen from "@/assets/images/icon_svg/ic_four_screen.svg";
import icFourScreenSelect from "@/assets/images/icon_svg/ic_four_screen_select.svg";
import icStationScreen from "@/assets/images/icon_svg/ic_station_screen.svg";
import icStationScreenSelect from "@/assets/images/icon_svg/ic_station_screen_select.svg";

import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapMarkerInfoData from "@/components/map/data/MapMarkerInfoData";
import MapLineData from "@/components/map/data/MapLineData";
import {fetchEventLiveList, fetchEventJoinList} from "@/api/dashboard";
import windDirection from "@/util/windDirection";
import {
  fetchPointCreate,
  fetchPointModify,
  fetchPointDelete,
  fetchPointList,
  fetchPointCheckUpdate,
  getPointTypeName
} from "@/api/point";
import {fetchLocation} from "@/api/location";
import {format} from "@/mixins/format";
import {fetchEvent, fetchEventLogList, fetchNoticeList, getNoticeImageUrl, changeEventLogData} from "@/api/event";
import {fetchVodList} from "@/api/contents";

import {fetchChannelList, getLiveUrl, fetchChannelCheckStream} from "@/api/channel";
import {playerPopOpen2} from "@/util/popup";

import moment from "moment";
import { mapState } from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import {getMobileImgDefault, getDeviceImgUrl, getDeviceImgDefault, fetchDeviceDataInfo, tempFetchStationThumbnailUrl, fetchDeviceLogList} from "@/api/device"
import {getIpGet, getIpLatLng} from "@/util/common";
// import Video from "@/components/video/videoPlayer";
import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";


let deviceTypeName = "DEVICE";
let supportTypeName = "SUPPORT";
let pointTypeName = "POINT";
let noticeTypeName = "NOTICE";


import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
export default {
  name: "Home",
  components: {
    Map,
    
    // Video, 
    
    MapButtonComponent
   ,
    WebRTC_WOWZA,
    /*MapStationLive*/},

  mixins: [format],
  props: {
    eventId: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // 신현호

      speakerStates: {},
      selectedSoundId: null,
      moment: moment,

      userId : localStorage.getItem("userId"),
      accId: null,
      ipToLat : null,
      ipToLng : null,
      MAP_TAB_INDEX: 1, // 지도가 있는 탭의 인덱스
      isSpeakerOn: false,
      screenVisible: 1,
      screenType: [{
        id : 0,
        imgDefault : icOneScreen,
        imgSelected : icOneScreenSelect,
      },
      {
        id : 1,
        imgDefault : icFourScreen,
        imgSelected : icFourScreenSelect,
      },
      {
        id : 2,
        imgDefault : icStationScreen,
        imgSelected : icStationScreenSelect,
      }],

      eventInfo: null,

      controllerList: [],
      controllerCount: 0,
      workerList: [],
      workerCount: 0,
      deviceList: [],
      deviceCount: [],
      pointList: [],
      pointCount: 0,

      showThirdDiv: false,
      showFourthDiv:false,

      selectTeb: null,
      showType: [ {
        type : deviceTypeName,
        title : localStorage.getItem("accountId") == 'komipo' ? '실시간 로봇' : this.$t("overall-data-now-device"),
      }, {
        type : supportTypeName,
        title : this.$t("site-view-title-member"),
      }, {
        type : pointTypeName,
        title : this.$t("site-view-title-point"),
      }, {
        type : noticeTypeName,
        title : this.$t("site-view-title-notice"),
      }],
      showCheckBoxType: [{
        type: deviceTypeName,
        title: localStorage.getItem("accountId") == 'komipo' ? '로봇' : this.$t("site-view-title-drone")
      },{
        type: supportTypeName,
        title: this.$t("site-view-title-user")
      },{
        type: pointTypeName,
        title: this.$t("site-view-title-point")
      }],
      checkBoxType: [deviceTypeName, supportTypeName, pointTypeName],

      interval: null,
      channelInterval: null,
      mapCountInterval : null,
      selectData: null,
      mapInfoOldData : null,
      pointServerTime: null,
      size: 100,

      channelStreamIds : [],
      channelList : [],
      viewChannelList: [],

      noticeTotal: 0,
      noticeSize: null,
      currentPage: 0,

      noticeList:[],
      noticeCount:0,
      file: "",
      img: "",

      windowHeight: window.innerHeight,
      typeChangeMapShowHeight: window.innerHeight - 0,
      loadMap: 'kakao',
      isActive: true,
      isTabActive: true,

      //스테이션 추가
      stationWorker : null,
      stationInfo : null,
      stationChannel : null,
      stationStreamingImg:null,
      stationIsStreamingImg: false,

      isFirstMove : false,
      department : {},

      //라이브 자동 버튼
      isChecked: true,
      //드론 비행 알림 공지 팝업
      isFlightAlertModal: false,
      eventLogList: [],

      //현장 누적 카운트
      isLogCountShow : false,
      deviceLogCount: 0,
      deviceLiveCount: 0,
      workerLiveCount: 0,
      vodCount: 0,
      vodLiveCount: 0,
      memberCount: 0,
      eventOpenCount: 0,
    }
  },
  watch: {
    currentLang(value) {
      this.initMapData(value);
    },
    selectData : {
      deep : true,
      handler(data) {
        var isSelected = data != null
        var type = null;
        if(data != null) {
          type = this.getSelectTypeToMapDataType(data.selectType);
        }
        this.createMapInfo(type, data, isSelected);
      },
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      isAdmin: state => state.store.isAdmin,
      userType: state => state.store.userType,
    }),
    deviceTypeName : function(){
      return deviceTypeName;
    },
    supportTypeName : function(){
      return supportTypeName;
    },
    pointTypeName : function(){
      return pointTypeName;
    },
    noticeTypeName : function(){
      return noticeTypeName;
    },

  },
  async mounted() {
    this.initButtonMap()

    this.getEventLogList();
    this.getEvent();
    this.getVodCount();
    this.getEventJoinWorkerCount();
    this.getDeviceLogCount();

    this.mapCountInterval = setInterval(() => {
      this.getEventLogList();
      this.getDeviceLogCount();
      this.getVodCount();
      this.getEventJoinWorkerCount();
    },5000);

    this.interval = setInterval(() => {
      this.getEventDevice();
      this.getEventWorker();
      this.getEventPoint();
      this.getEventWorkerStation();
    }, 2000);

    this.channelInterval = setInterval(() => {
      this.getChannelList();
      // this.getChannelStation();
    }, 2000);


    var loadInterval = setInterval(() => {
      if(this.$refs.map == undefined){
        return
      }

      clearInterval(loadInterval);
      this.createMapMarker(MapDataType.ID_SITE, this.eventInfo, false);
    },1000)


    await this.getChannelList();
    await this.getEventDevice();
    await this.getEventWorker();
    await this.getEventPoint();
    await this.getEventWorkerStation();
    // await this.getChannelStation();

    this.handleTab(this.deviceTypeName);

    getIpGet().then((res) => {
      getIpLatLng(res.data.ip).then((res) => {
        this.ipToLat = res.data.latitude;
        this.ipToLng = res.data.longitude;
      });
    });

    navigator.geolocation.getCurrentPosition(function (pos){
      pos.coords.latitude, pos.coords.longitude;
    },function (error){
      console.log(error);
    });

    // 1. ResizeObserver 객체 만들기
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        let latlng = this.$refs.map.getMapCenter();
        width;
        height;
        this.$refs.map.mapDomWidth = width
        this.$refs.map.setLayout();
        this.$refs.map.onLngLatToMoveCenter(latlng.lng, latlng.lat);
      }
    });

    // 2. 감지할 요소 추가하기
    observer.observe(this.$refs.map_div);
  },
  created() {
    //accountId 받아오기
    this.accountId = localStorage.getItem("accountId");

    this.$store.dispatch("store/SET_NAVBAR", {nav: 9, sub: 0});
    if (this.eventId == null && this.$route.params.eventId != undefined) {
      this.eventId = this.$route.params.eventId
    }
    this.getNoticeList();

    window.addEventListener('resize', this.handleResize);

    // mapButtonEventBus.$on('isShowMapTypeChange', (isShowMapTypeChange)=> {
    //   this.isShowMapTypeChangeModal = isShowMapTypeChange
    // })
  },
  methods: {

    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showMapCenter()
      this.$refs.buttonMap.showMapUserCenter()
      this.$refs.buttonMap.showMapFlightAlert()
      this.$refs.buttonMap.showMapPoint()
      this.$refs.buttonMap.showWeather()
      this.$refs.buttonMap.showMapPositionAndLine()
      this.$refs.map.onSetIsMarkerClick();
    },

    //공지 이미지 팝업 생성(크게보기)
    popImg(data){
      var popX = window.innerWidth/2;

      var imgUrl = getNoticeImageUrl(data.accountId, data.eventId, data.index);
      var img1 = new Image();

      img1.src = imgUrl;

      img1.onload = function() {
        document.body.appendChild(img1);
        var imgWidth = img1.clientWidth;
        var imgHeight = img1.clientHeight;

        popX = popX-(imgWidth/2);

        var popSize = "width="+ imgWidth+",height="+imgHeight+",top=250,left="+popX+", scrollbars=no,";

        var imgWin = window.open(imgUrl ,"", popSize);
        imgWin.document.write("<body style='margin:0;'>")
        imgWin.document.write("<img src="+imgUrl+"/>");
      }
    },
    //탭 보이기
    handleVisibleTab(){
      this.isTabActive = !this.isTabActive;
    },
    //페이지 리사이즈
    handleResize(e) {
      e;
      this.windowHeight = window.innerHeight;
      this.typeChangeMapShowHeight = this.windowHeight - 90;
    },
    //region image url
    getNoticeImageUrl(data){
      return getNoticeImageUrl(data.accountId, data.eventId, data.index);
    },
    getDeviceImg(data){
      if(data.assignedDeviceType == "Mobile"){
        return getMobileImgDefault(data.assignedDeviceType);
      }
      return data.assignedDeviceDefaultIsImage ? getDeviceImgDefault(data.assignedDeviceManufacturerId, data.assignedDeviceModelId) : getDeviceImgUrl(data.accountId, data.assignedDeviceId) + '?date='+(new Date)
    },
    getDeviceImg2(data){
      return getDeviceImgDefault(data.manufacturerId, data.modelId);
    },
    //endregion

    //region api 요청
    getDataToLocation(dataList, type){
      var leaveIds = [];
      dataList.forEach(item => {
        leaveIds.push(item.selectId)
      });
      var mapDataType = this.getSelectTypeToMapDataType(type);
      switch (type){
        case "Worker":
          mapDataType = MapDataType.ID_USER
          break;
        case "Controller":
          mapDataType = MapDataType.ID_CONTROLLER
          break;
        case "Drone":
          mapDataType = MapDataType.ID_DRONE
          break;
        case "Streamer":
          mapDataType = MapDataType.ID_STREAMER
          break;
      }

      if(mapDataType != null) {
        this.$refs.map.onLeaveIds(mapDataType, leaveIds);
      }

      dataList.forEach((element) => {
        this.getLocation(element)
      });
    },
    async getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      await fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.eventInfo.selectId = "MapMarker";
          this.initMapData(this.currentLang);
          this.department = res.data.data.managerDepartment;
        }
      });
    },
    async getEventDevice() {
      let params = {
        eventId: this.eventId
      };
      await fetchEventLiveList('device', params).then((res) => {
        if (res.data.result == 0) {
          this.deviceCount = res.data.data.total
          let newList = res.data.data.content;
          newList.map((item) => {
            item.selectId = item.deviceLogId;
            item.selectType = "Drone";
            let findIndex = this.deviceList.findIndex((old) => old.deviceLogId == item.selectId);
            if(findIndex != -1) {
              item.distance = this.deviceList[findIndex].distance;
              item.gimbalDirection = this.deviceList[findIndex].gimbalDirection;
              item.height = this.deviceList[findIndex].height;
            }
          })
          this.deviceList = newList;
          this.getDataToLocation(this.deviceList, "Drone");
          let findIds = this.deviceList.map((item) => item.deviceLogId)
          this.removeChannelStreamIds(findIds, "Drone");
        }
      });
    },
    async getEventWorker() {
      let params = {
        workerTypes: 'Controller,Streamer,Worker',
        eventId: this.eventId,
      };

      await fetchEventLiveList('worker', params).then((res) => {
        if (res.data.result == 0) {
          this.workerCount = res.data.data.total;
          this.workerList = res.data.data.content;

          this.workerList.map((item) => {
            item.selectId = item.workerId;
            item.selectType = item.type;
          })

          var workerList = this.workerList.filter(item => item.type == "Worker" || item.type == "Streamer");
          var controller = this.workerList.filter(item => item.type == "Controller");
          let findIds = workerList.map((item) => item.workerId);
          this.removeChannelStreamIds(findIds, "Mobile");

          this.getDataToLocation(workerList, "Worker");
          this.getDataToLocation(workerList, "Streamer");
          this.getDataToLocation(controller, "Controller");
        }
      });
    },
    async getChannelStation() {
      var params = {
        pageNumber: 0,
        pageSize: 1,
        eventId: this.eventId,
        assignedDeviceTypes : "Station",
        assignedStatus: "Assigned",
      };

      await fetchChannelList(params).then((res) => {
        if (res.data.result == 0) {
          if(res.data.data.content.length > 0) {
            let isLoad = (this.stationChannel != null && this.stationChannel.isLoad == true) ? true : false;
            this.stationChannel = res.data.data.content[0];
            this.stationChannel.isLoad = isLoad;
          } else {
            this.stationChannel = null
          }
        }
      });
    },
    async getLocation(data) {
      if (data == null) {
        return;
      }

      var offsetLocalTime = null;
      if(data.checkInData != undefined && data.checkInData != null) {
        offsetLocalTime = moment(data.checkInData).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }
      var isMain = this.selectData != null ? this.selectData.selectId == data.selectId : false;

      var mapDataType = this.getSelectTypeToMapDataType(data.selectType);
      let params = {
        refId: data.selectId,
        eventId: this.eventId,
        offsetIndex: this.$refs.map.onGetLineSize(mapDataType, data.selectId),
        offsetLocalTime: offsetLocalTime,
      };

      fetchLocation(params).then((res) => {
        if (res.data.result == 0) {
          if (res.data.data.content.length > 0) {
            res.data.data.content.sort(function (a, b) {
              return a.index - b.index;
            })
            var location = res.data.data.content[res.data.data.content.length - 1];

            if(mapDataType == MapDataType.ID_DRONE) {
              data.distance = location.distance;
              data.gimbalDirection = location.gimbalDirection;
              data.height = location.height;
              data.rotate = location.azimuth;

              //드론 처음 위치 정보 가져오면 그때 이동 (한번만 이동)
              if(this.isFirstMove == false) {
                this.isFirstMove = true;
                this.$refs.map.onLngLatToMoveCenter(location.lng, location.lat);
              }
            }
            data.lat = location.lat;
            data.lng = location.lng;

            if(this.selectData != null && this.selectData.selectId == data.selectId) {
              this.changeMapLatLng(location.lat, location.lng);
              this.selectData.lat = data.lat;
              this.selectData.lng = data.lng;
            } else if (this.selectData == null) {
              this.changeMapLatLng(this.eventInfo.lat, this.eventInfo.lng);
            }

            this.createMapMarker(mapDataType, data, isMain);
            this.createMapLine(mapDataType, data, res.data.data.content);
          }
        }
      });
    },
    async getChannelList() {
      // let assignedDeviceLogIds = this.channelStreamIds.filter((item) => item.type == "Drone").map((item) => item.channelStreamId).join(",");
      // let assignedWorkerIds = this.channelStreamIds.filter((item) => item.type == "Mobile").map((item) => item.channelStreamId).join(",");

      var params = {
        pageNumber: 0,
        pageSize: this.size,
        eventId: this.eventId,
        // assignedWorkerIds: assignedWorkerIds == "" ? " " : assignedWorkerIds,
        // assignedDeviceLogIds : assignedDeviceLogIds == "" ? " " : assignedDeviceLogIds,
        assignedStatus: "Assigned",
      };

      // if(assignedWorkerIds != "" || assignedDeviceLogIds != "") {
      //   if(assignedWorkerIds == "") {
      //     delete params.assignedWorkerIds
      //   }
      //
      //   if(assignedDeviceLogIds == "") {
      //     delete params.assignedDeviceLogIds
      //   }
      // }
      await fetchChannelList(params).then((res) => {
        if (res.data.result == 0) {
          this.channelList = res.data.data.content;

          //채널 추가 및 삭제 로직 & 삭제 아이디 찾기 위한 데이터 넣기
          let delIds = [];
          this.viewChannelList.forEach(item => {
            delIds.push(item.channelId.toString());
          });

          //streamIds에서 삭제할 데이터.
          let delStreamIds = [];

          //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
          this.channelList.forEach(live => {
            delStreamIds.push(this.checkStreamId(live))

            let findIndex = delIds.findIndex(item => item == live.channelId);
            if (findIndex != -1) { //삭제 안될 대상 걸러내기.
              delIds.splice(findIndex, 1);
             }
          });

          //streamIds에 존재하는 것중 channelList에 없는거 찾아서 삭제하기.
          this.channelStreamIds.forEach(item=> {
             let tempIndex =  delStreamIds.findIndex(delItem => delItem.channelStreamId == item.channelStreamId)
             if(tempIndex == -1){
              var delId = this.channelStreamIds.findIndex(delItem => delItem.channelStreamId == item.channelStreamId)
              this.channelStreamIds.splice(delId,1)
             }
          })


          //삭제 아이디 있는 경우 채널에서 삭제
          delIds.forEach(delId => {
            let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);
            if (findIndex != -1) {
              let channel = this.viewChannelList[findIndex];
              this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, channel.channelId);
              this.viewChannelList.splice(findIndex, 1);

            }
          })

          //채널 추가
          for (var i = 0; i < this.channelList.length; i++) {
            this.addStreamChanelToChannel(this.channelList[i])
          }

          //기본에 데이터 변경
          this.channelList.forEach((item) => {
            this.channelDataChange(item)
          })
        }
      });
    },
    addStreamChanelToChannel(channel){
      fetchChannelCheckStream(channel.channelId).then((res)=>{
        if(res.data.result != 0 || res.data.data.bytesIn == 0) {
          return
        }
        if (this.isChecked) {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == channel.channelId);
          if (findIndex == -1) {
            channel.isLoad = false;
            if( this.addCheckViewChannel() ){
              let channelStreamId = channel.assignedDeviceType == "Drone" ? channel.assignedDeviceLogId : channel.assignedWorkerId
              this.channelStreamIds.push({type : channel.assignedDeviceType, channelStreamId:channelStreamId})
              this.viewChannelList.push(channel);
            }
          }
        }else{
          var findIndex = -1;
          this.channelStreamIds.forEach((item) => {
            if(item.type == channel.assignedDeviceType && (item.channelStreamId == channel.assignedDeviceLogId||item.channelStreamId == channel.assignedWorkerId)){
              if( this.addCheckViewChannel() ){
                findIndex = this.viewChannelList.findIndex(viewChannel =>
                  (viewChannel.assignedDeviceType == "Drone" && item.channelStreamId == viewChannel.assignedDeviceLogId) ||
                  (viewChannel.assignedDeviceType == "Mobile" && item.channelStreamId == viewChannel.assignedWorkerId)
                );
                if (findIndex == -1) {
                  channel.isLoad = false;
                  this.viewChannelList.push(channel);
                }
              }
            }
          })
        }
      })
    },
    addCheckViewChannel(){
      var result = false;

      if(this.screenVisible == 1 && this.viewChannelList.length < 3 ) {
        result = true;
      }

      if(this.screenVisible == 2 && this.viewChannelList.length < 4 ) {
        result = true;
      }

      if(this.screenVisible == 3 && this.viewChannelList.length < 9 ) {
        result = true;
      }

      return result;
    },
    //endregion

    //region 맵 데이터 표출 api 요청
    getDeviceLogCount(){
      if(this.$refs.map == undefined) {
        return;
      }

      let params = {
        pageSize : 1,
        eventId : this.eventId,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceLogList(params).then((res) => {
        this.onSetDeviceLogCount(res.data.data.total);
      });
    },
    getVodCount() {
      // if(this.$refs.map == undefined) {
      //   return;
      // }

      let params = {
        eventId: this.eventId,
        pageSize: 1,
      };
      fetchVodList(params).then((res) => {
        this.onSetVodCount(res.data.data.total);
      });
    },
    getEventJoinWorkerCount(){
      if(this.$refs.map == undefined) {
        return;
      }

      let params = {
        pageSize : 1,
        eventId : this.eventId,
        workerTypes : 'Worker,Controller,Streamer'
      };
      fetchEventJoinList('worker', params).then((res) => {
        this.onEventJoinWorkerCount(res.data.data.total);
      });
    },
    getEventLogList(){
      let params = {
        page_size: 30,
        eventId : this.eventId,
      };
      fetchEventLogList(params).then((res)=>{
        if(this.$refs.map == undefined) {
          return;
        }
        let list = changeEventLogData(res.data.data.content, this.eventLogList);
        if(list != null) {
          this.eventLogList = list;
        }
      });
    },
    getNoticeList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.noticeSize,
        eventId : this.eventId,
        statuses:'Used,Delete',
      };
      fetchNoticeList(params, this.id).then((res) => {
        this.noticeTotal = res.data.data.total;
        this.noticeList = res.data.data.content;
      });
    },
    //endregion

    //region 플레이어 및 채널 관련
    playerRemove(id){
      let findIndex = this.viewChannelList.findIndex(item => item.channelId == id);
      if(findIndex != -1){
        this.viewChannelList.splice(findIndex,1);
      }
    },
    channelChange(event,data){
        this.checkStreamId(data)
        if(!event.target.checked){
          this.playerRemove(data.channelId)
        }
      switch (this.screenVisible){
        case 1 :
          if(this.channelStreamIds.length > 3) {
            this.channelStreamIds.pop()
            event.target.checked= false
            return;
          }
          break;
        case 2 :
          if(this.channelStreamIds.length > 4) {
            this.channelStreamIds.pop()
            event.target.checked= false
            return;
          }
          break;

        case 3 :
          if(this.channelStreamIds.length > 9) {
            this.channelStreamIds.pop()
            event.target.checked= false
            return;
          }
          break;
      }
      if(!this.isChecked) {
        this.removeChannelStreamDataToViewChannel();
      }
    },
    playerLoadData(id, isLoad) {
      var isFindIndex = (data) => {
        return data.channelId == id;
      };

      var findIndex = this.viewChannelList.findIndex(isFindIndex);
      if (findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    getLiveData(index) {
      if(this.viewChannelList[index] == undefined) {
        return;
      }
      return this.viewChannelList[index];
    },
    getStreamIds(data){
      var id = data.assignedDeviceType === 'Drone' ? data.assignedDeviceLogId : data.assignedWorkerId
      var findIndex = this.channelStreamIds.findIndex(item =>  item.channelStreamId == id )
      return findIndex != -1 ? findIndex : null
    },
    getViewlChannelByChannel(channel){ //streamId를 통해서 viewChannel에 index값을 찾는다.
      var findIndex = this.viewChannelList.findIndex(viewChannel =>
      viewChannel.channelId == channel.channelId );
      return findIndex != -1 ? findIndex : null
    },
    getLiveUrl(channelData) {
      var url = getLiveUrl(channelData);
      if (url == '-') {
        return ''
      }
      return url
    },
    playerStationLoadData(id, isLoad) {
      if (this.stationChannel != null && this.stationChannel.channelId == id) {
        this.stationChannel.isLoad = isLoad;
        this.$set(this.stationChannel, "isLoad", isLoad);
      }
    },
    channelDataChange(changeData){
      let findIndex = this.viewChannelList.findIndex(item => item.channelId == changeData.channelId);
      if(findIndex != -1) {
        this.viewChannelList[findIndex].height = changeData.height
        this.viewChannelList[findIndex].hspeed = changeData.hspeed
        this.viewChannelList[findIndex].vspeed = changeData.vspeed
        this.viewChannelList[findIndex].battery = changeData.battery
        this.viewChannelList[findIndex].lng = changeData.lng
        this.viewChannelList[findIndex].lat = changeData.lat
        this.viewChannelList[findIndex].distance = changeData.distance
        this.viewChannelList[findIndex].heartRate = changeData.heartRate
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    //endregion

    //region 맵 관련 함수
    initMapData(value) {
      if (value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      this.$nextTick(function () {
        this.pointServerTime = null;

        this.onMapCenter();
        
        this.handleShowType();

      })
    },
    onMapCenter() {
      if (this.eventInfo == null) {
        return;
      }

      if(this.$refs.map == undefined) {
        return;
      }

      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },

    getSelectTypeToMapDataType(selectType){
      var mapDataType = null;
      if(selectType == null || selectType == undefined) {
        return mapDataType;
      }

      switch (selectType){
        case "Drone" :
          mapDataType = MapDataType.ID_DRONE;
          break;
        case "Worker" :
          mapDataType = MapDataType.ID_USER;
          break;
        case "Streamer" :
          mapDataType = MapDataType.ID_STREAMER;
          break;
        case "Controller" :
          mapDataType = MapDataType.ID_CONTROLLER;
          break;
        case "Point" :
          mapDataType = MapDataType.ID_POINT;
          break;
        case "Station" :
          mapDataType = MapDataType.ID_STATION;
          break;
      }
      return mapDataType;
    },
    createMapInfo(mapDataType, data, isSelected){
      if(this.mapInfoOldData != null) {
        this.$refs.map.onInfoMarkerRemove(this.mapInfoOldData.type, "event_info");
      }

      if(data == null) {
        return;
      }

      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = "event_info";
      mapMarkerData.lng = data.lng;
      mapMarkerData.lat = data.lat;

      let mapMarkerInfoData = new MapMarkerInfoData();
      mapMarkerInfoData.mapDataType = mapDataType;

      switch (mapDataType) {
        case MapDataType.ID_DRONE :
          mapMarkerInfoData.type = MapDataType.ID_MAP_INFO_DRONE;
          mapMarkerInfoData.setDroneInfoData(data);
          break;
        case MapDataType.ID_STREAMER :
        case MapDataType.ID_USER :
        case MapDataType.ID_CONTROLLER :
          mapMarkerInfoData.type = MapDataType.ID_MAP_INFO_USER;
          mapMarkerInfoData.setUserInfoData(data);
          break;
        case MapDataType.ID_POINT:
          mapMarkerInfoData.typeName = this.$t(getPointTypeName(data.type));
          mapMarkerInfoData.type = MapDataType.ID_POINT;
          mapMarkerInfoData.name = this.$t(getPointTypeName(data.type));
          mapMarkerInfoData.setPointInfoData(data);
          break;
        default :
          mapMarkerInfoData = null;
      }

      if(mapMarkerInfoData != null) {
        if(isSelected) {
          this.mapInfoOldData = mapMarkerInfoData;
          this.$refs.map.onInfoMarkerShow(mapMarkerInfoData.type, mapMarkerData, mapMarkerInfoData, this.mapItemClickListener);
        }
      }
    },
    onMapItemClick(id,type,isMain){
      console.log("click!!")
      var findList = null;
      switch (type) {
        case MapDataType.ID_POINT :
          findList = this.pointList;
          break;
        case MapDataType.ID_DRONE :
          findList = this.deviceList;
          break;
        case MapDataType.ID_STREAMER :
        case MapDataType.ID_USER :
        case MapDataType.ID_CONTROLLER :
          findList = this.workerList;
          break;
        default :
          findList = null;
      }

      console.log(findList)

      if(findList != null) {
        isMain;
        let findIndex = findList.findIndex((item) => item.selectId == id);
        if(findIndex != -1) {
          let findData = findList[findIndex];
          console.log(findData)
          this.handleSelectEl(findData);
        }
      }
    },
    mapItemClickListener(data, isSave){
      if(isSave && data.mapDataType == MapDataType.ID_POINT) {
        var filter = this.pointList.filter((item)=> item.selectId == data.selectId);
        if(filter.length > 0) {
          filter[0].memo = data.memo;
          filter[0].editMode = false;
          this.onPointModify(filter[0]);
        }
      }

      if(!isSave || data.mapDataType != MapDataType.ID_POINT) {
        this.handleSelectEl(data);
      }
    },
    changeMapLatLng(lat, lng){
      if(lat == null || lat == undefined || lng == null || lng == undefined) {
        return;
      }

      this.$refs.map.onSetLngLat(lng, lat);
    },
    createMapMarker(mapDataType, data, isMain) {
    
      if(this.$refs.map == undefined) {
        return;
      }
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = data.selectId;
      mapMarkerData.isMain = isMain;
      switch (mapDataType) {
        case MapDataType.ID_SITE :
          mapMarkerData.lat = data.lat;
          mapMarkerData.lng = data.lng;
          break;
        case MapDataType.ID_DRONE :
          mapMarkerData.setDroneData(data);
          break;
        case MapDataType.ID_USER:
        case MapDataType.ID_STREAMER:
        case MapDataType.ID_CONTROLLER:
          mapMarkerData.setUserData(data);
          break;
        case MapDataType.ID_STATION:
          mapMarkerData.setStationData(data);
          break;
      }

      if (mapDataType == MapDataType.ID_USER) {
        var filtr = this.channelList.filter(item => {
          return item.assignedWorkerId == data.selectId;
        })
        if (filtr.length > 0) {
          mapMarkerData.isUserLive = true;
        }
      }

      if (this.$refs.map.onIsMarker(mapDataType, data.selectId)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    createMapLine(mapDataType, data, locationGroup) {
      var mapLineData = new MapLineData();
      mapLineData.id = data.selectId;
      mapLineData.locationGroup = locationGroup;
      if (this.$refs.map.onIsLine(mapDataType, data.selectId)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    //endregion

    //region 데이터 클릭
    handleShowType() {
      if(this.$refs.map == undefined) {
        return;
      }

      if (this.checkBoxType.includes(this.deviceTypeName)) {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, true);
      } else {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, false);
      }
      if (this.checkBoxType.includes(this.supportTypeName)) {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_CONTROLLER, true);
      } else {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_CONTROLLER, false);
      }
      if (this.checkBoxType.includes(this.supportTypeName)) {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_USER, true);
      } else {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_USER, false);
      }
      if (this.checkBoxType.includes(this.pointTypeName)) {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, true);
      } else {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, false);
      }
      if (this.checkBoxType.includes(this.pointTypeName)) {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, true);
      } else {
        this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, false);
      }
    },
    handleUnSelectEl() {
      var sendSelect = this.selectData;
      this.selectData = null;
      this.handleSelectAction(sendSelect, false);
    },
    handleSelectEl(data) {
      if(this.selectData != null) {
        let selectId = this.selectData.selectId;
        this.handleUnSelectEl();
        if (selectId == data.selectId) {
          return;
        }
      }
      this.selectData = data;
      this.handleSelectAction(this.selectData, true);
    },
    handleTab(type) {
      this.selectTeb = type;
    },
    handleFloatTab(id) {
      //탭 선택, 1,4 채널 시 4 -> 1  이동시 선택 하나만 남기고 제외
      if(this.isActive) {
        switch (id) {
          case 1 :
            if(this.channelStreamIds.length > 3){
              this.channelStreamIds.splice(3, this.channelStreamIds.length - 1);
              this.viewChannelList.splice(3, this.channelStreamIds.length - 1)
            }
            break;
          case 2 :
            if(this.channelStreamIds.length > 4){
              this.channelStreamIds.splice(4, this.channelStreamIds.length - 1);
              this.viewChannelList.splice(4, this.channelStreamIds.length - 1)
            }
            break;
          case 3 :
            if(this.channelStreamIds.length > 9){
              this.channelStreamIds.splice(9, this.channelStreamIds.length - 1);
              this.viewChannelList.splice(9, this.channelStreamIds.length - 1)
            }
            break;
        }
      }
      this.screenVisible = id;
    },
    handleSelectAction(data, isSelected) {
      var mapMarkerData = new MapMarkerData();
      mapMarkerData.id = data.selectId;

      var mapDataType = this.getSelectTypeToMapDataType(data.selectType);
      mapMarkerData.mapDataType = mapDataType;
      switch (mapDataType) {
        case MapDataType.ID_DRONE :
          mapMarkerData.setDroneData(data);
          break;
        case MapDataType.ID_STREAMER :
        case MapDataType.ID_USER :
        case MapDataType.ID_CONTROLLER :
          mapMarkerData.setUserData(data);
          break;
        case MapDataType.ID_POINT :
          mapMarkerData.pointType = data.type;
          mapMarkerData.setPointData(data);
          break;
      }
      this.$refs.map.onMarkerIsSelected(mapDataType, mapMarkerData, isSelected);
      if (isSelected == true) {
        if(data.lng == undefined || data.lng == null || data.lat == undefined || data.lng == null) return //lng,lat 가 널이거나 언디파인드면 지도가 이상한곳으로 이동됨.
        this.$refs.map.onLngLatToMoveCenter(data.lng, data.lat);
        this.changeMapLatLng(data.lat, data.lng);
      } else {
        this.changeMapLatLng(this.eventInfo.lat, this.eventInfo.lng);
      }
    },
    //endregion
    removeChannelStreamDataToViewChannel(channelStreamId) {
      if(channelStreamId != undefined || channelStreamId == null) {
        var delChannelId = []
        this.viewChannelList.forEach((item) => {
          var findIndex = this.channelStreamIds.findIndex(streamId => item.assignedDeviceType == streamId.type && streamId.channelStreamId == (streamId.type == "Drone" ? item.assignedDeviceLogId : item.assignedWorkerId))
          if(findIndex == -1) {
            delChannelId.push(item.channelId)
          }
        })

        if(delChannelId.length > 0){
          delChannelId.forEach((item) => {
            var findIdx = this.viewChannelList.findIndex((viewChannel) => {viewChannel.channelId == item});
            this.viewChannelList.splice(findIdx,1)
          })
        }
        // this.childViewSetChannelList();
        return;
      }
      var findIdx = this.viewChannelList.findIndex((item) => item.assignedDeviceType == channelStreamId.type && channelStreamId.channelStreamId == (channelStreamId.type == "Drone" ? item.assignedDeviceLogId : item.assignedWorkerId))
      this.viewChannelList.splice(findIdx,1)
      // this.childViewSetChannelList();
    },

    removeChannelStreamIds(findIds, type){
      let delLogIds = []

      let channelFilter = this.channelStreamIds.filter((item) => item.type == type)
      channelFilter.forEach((channel) => {
        if(!findIds.includes(channel.channelStreamId)) {
          delLogIds.push(channel);
        }
      })

      if(delLogIds.length > 0) {
        delLogIds.forEach((channel) => {
          let findIndex = this.channelStreamIds.findIndex((item) => {item.channelStreamId == channel.channelStreamId})
          if(findIndex != -1) {
            let delItem = this.channelStreamIds.splice(findIndex,1)
            this.removeChannelStreamDataToViewChannel(delItem);
          }
        })
      }
    },

    //region station
    convertWindDirection(data) {
      return windDirection[Math.floor((Number(data) + 11.25) / 22.5)];
    },
    convertStationPowerInfo(power){
      var result = "N/A"
      switch (power) {
        case "On":
          result = "켜짐"
          break;
        case "Off":
          result = "꺼짐"
          break;
      }
      return result
    },
    convertStationStatusInfo(status){
      var result = "N/A"
      switch (status) {
        case "None":
          result = "작업 상태 알 수 없음"
          break;
        case "Initializing":
          result = "장치 초기화 중"
          break;
        case "Waiting":
          result = "장치 작동 대기중"
          break;
        case "Running":
          result = "장치 작동중"
          break;
        case "Error":
          result = "장치 오류 발생"
          break;
      }
      return result
    },
    updateStationInfo(stationInfo, weatherInfo){
      if(this.stationInfo == null) {
        this.stationInfo = {}
      }

      this.stationInfo.power = stationInfo.deviceStatus == null ? "N/A" : this.convertStationPowerInfo(stationInfo.deviceStatus.powerStatus);
      this.stationInfo.status = stationInfo.deviceStatus == null ? "N/A" : this.convertStationStatusInfo(stationInfo.deviceStatus.status);
      this.stationInfo.lat = stationInfo.location == null ? this.eventInfo.lat : stationInfo.location.lat;
      this.stationInfo.lng = stationInfo.location == null ? this.eventInfo.lng : stationInfo.location.lng;
      this.stationInfo.humidity = stationInfo.humidity == null ? (weatherInfo == null ? null : weatherInfo.humidity.replace("%","")) : stationInfo.humidity.humidity;
      this.stationInfo.pressure = stationInfo.pressure == null ? (null) : stationInfo.pressure.pressure;
      this.stationInfo.temperature = stationInfo.temperature == null ? (weatherInfo == null ? null : weatherInfo.temperatures) : stationInfo.temperature.temperature;
      this.stationInfo.windDirection = stationInfo.windDirection == null ? (weatherInfo == null ? null : weatherInfo.windDirectionName) : this.convertWindDirection(stationInfo.windDirection.direction);
      this.stationInfo.windSpeed = stationInfo.windSpeed == null ? (weatherInfo == null ? null : weatherInfo.windSpeed.replace("m/s","")) : stationInfo.windSpeed.speed;
    },
    async getEventWorkerStation() {
      let params = {
        workerTypes: 'Station',
        eventId: this.eventId,
      };

      if(this.screenVisible == 2 && this.deviceList.length > 0) {
        var streamDevice = this.deviceList[0];
        this.channelStreamIds = [];
        this.channelStreamIds.push({channelStreamId : streamDevice.selectId, type : 'Drone'})
      }

      if(this.stationStreamingImg != null && this.$refs.stationStreamingDiv != undefined) {
        var thisDiv = this.$refs.stationStreamingDiv;
        var img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width =this.width;
          canvas.height =this.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          thisDiv.style.backgroundImage = `url(${dataURL})`;
        };
        img.src = this.stationStreamingImg;
      }

      let weatherInfo = (this.$refs.map != undefined && this.$refs.map != null) ? this.$refs.map.getWeatherInfo() : null;
      await fetchEventLiveList('worker', params).then((res) => {
        if (res.data.result == 0) {
          if(res.data.data.content.length > 0) {
            this.stationWorker = res.data.data.content[0];
            let params = {
              eventId: this.eventId,
            }
            this.stationWorker.selectId = this.stationWorker.workerId;
            this.stationWorker.selectType = "Station";

            fetchDeviceDataInfo(this.stationWorker.workerId, "Station", params).then((res) => {
              if (res.data.result == 0) {
                this.updateStationInfo(res.data.data, weatherInfo);
              }
            });

            if(this.eventInfo != null && this.eventInfo != undefined) {
              let mapDataType = MapDataType.ID_STATION;
              this.stationWorker.lat = this.eventInfo.lat;
              this.stationWorker.lng = this.eventInfo.lng;
              this.createMapMarker(mapDataType, this.stationWorker, false)
            }

            //this.getLocation(this.stationInfo, "station")
          } else {
            if(this.$refs.stationStreamingDiv != undefined) {
              this.$refs.stationStreamingDiv.style.background = 'none';
            }
            this.stationWorker = null;
            this.stationInfo = null;
            this.stationStreamingImg = null;
            this.stationIsStreamingImg = false;
            if(this.stationWorker != null) {
              this.$refs.map.onDeleteMarkerAt(MapDataType.ID_STATION, this.stationWorker.workerId);
            }
          }
        }

        if(this.stationWorker != null) {
          this.stationStreamingImg = tempFetchStationThumbnailUrl(this.eventInfo.accountId, this.stationWorker.workerId)
        }

      });
    },
    //endregion

    //region point
    onPointMapModify(data) {
      if(this.selectData != null ) {
        if(this.selectData.selectId == data.selectId && this.getSelectTypeToMapDataType(this.selectData.selectType)  == MapDataType.ID_POINT) {
          this.handleSelectEl(this.selectData);
        }
      }
      data.editMode = true;
      this.handleSelectEl(data);
    },
    getPointTypeName(type) {
      return getPointTypeName(type);
    },
    async getEventPoint() {
      let params = {
        eventId: this.eventId,
        serverTime: null,
      };

      if (this.pointServerTime != null) {
        params.serverTime = moment(this.pointServerTime).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      if (this.pointServerTime == null) {
        await fetchPointList(params).then((res) => {
          if (res.data.result == 0) {
            this.pointServerTime = res.data.data.serverTime
            this.pointCount = res.data.data.total
            this.pointList = res.data.data.content
            this.pointList.forEach((element) => {
              element.selectId = element.index;
              element.editMode = false;
              element.selectType = "Point";
              element.isOwner = this.userType != "User" ? true : this.userId == element.registerUserId;
              let pointMapMarker = new MapMarkerData();
              element.name = this.$t(getPointTypeName(element.type));
              pointMapMarker.setPointData(element);
              this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
            })
          }
        });
      } else {
        await fetchPointCheckUpdate(this.eventId, params).then((res) => {
          if (res.data.result == 0) {
            this.pointServerTime = res.data.data.serverTime
            var pointList = res.data.data.content
            pointList.map((item) =>{
              item.selectId = item.index;
              item.selectType = "Point";
              item.editMode = false;
              item.name = this.$t(getPointTypeName(item.type));
              item.isOwner = this.userType != "User" ? true : this.userId == item.registerUserId;
            });
            const deleteList = pointList.filter((element) => element.status == "Delete" || element.status == "Deactivate");
            const newList = pointList.filter((element) => {
              var isFindIndex = (data) => {
                return data.index == element.index;
              };
              var find = this.pointList.findIndex(isFindIndex);
              return element.status == "Activate" && find == -1;
            });

            deleteList.forEach((element) => {
              var isFindIndex = (data) => {
                return data.index == element.index;
              };
              var find = this.pointList.findIndex(isFindIndex);
              if (find != -1) {
                var delItem = this.pointList.splice(find, 1);
                this.$refs.map.onDeleteMarkerAt(MapDataType.ID_POINT, delItem[0].index);
                if(this.selectData != null && delItem[0].index == this.selectData.selectId && this.getSelectTypeToMapDataType(this.selectData.selectType) == MapDataType.ID_POINT ) {
                  this.handleSelectEl(this.selectData);
                }
              }
            })

            newList.forEach((element) => {
              element.isOwner = this.userType != "User" ? true : this.userId == element.registerUserId;
              let pointMapMarker = new MapMarkerData();
              pointMapMarker.setPointData(element);
              this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
              this.pointList.unshift(element)
            })
            this.pointCount = this.pointList.length;
          }
        });
      }
    },
    onPointCreate(data) {
      var sendData = data;
      fetchPointCreate(this.eventId, sendData).then((res) => {
        if (res.data.result == 0) {
          this.$refs.map.onPointCreateModalClose();
        }
      });
    },
    onPointModify(data) {
      var sendData = data;
      fetchPointModify(sendData.index, this.eventId, sendData).then((res) => {
        if (res.data.result == 0) {
          //this.$refs.map.onPointModify(null);
        }
      });
    },
    onPointDelete(data) {
      if (!confirm(this.$t("point-delete-alert-title"))) {
        return;
      }
      fetchPointDelete(data.index, this.eventId).then((res) => {
        if (res.data.result == 0) {
          var isFindIndex = (source) => {
            return source.index == data.index;
          };

          var find = this.pointList.findIndex(isFindIndex);
          if (find != -1) {
            this.pointList.splice(find, 1);
          }

          this.$refs.map.onDeleteMarkerAt(MapDataType.ID_POINT, data.index);

          if (this.selectData != null) {
            if( this.getSelectTypeToMapDataType(this.selectData.selectType) == MapDataType.ID_POINT ) {
              this.handleSelectEl(this.selectData);
            }
          }
          this.pointCount = this.pointList.length;
        }
      });
    },

    onPointCreateModalShow(isShow){
      if(this.selectData != null && isShow) {
        if(this.getSelectTypeToMapDataType(this.selectData.selectType)  == MapDataType.ID_POINT) {
          var filter = this.pointList.filter((item)=> item.selectId == this.selectData.selectId);
          if(filter.length > 0) {
            this.handleSelectEl(filter[0]);
          }
        }
      }
    },
    //endregion

    //
    playerOpen(type,id,index) {
      
      playerPopOpen2(type, id, index);
    },
     getHomeUrl(){
      /* 현장별 상황판 새 탭일 경우 홈
      var resultUrl = `/${this.accountId}/eventControl/${this.eventId}/SkyView`;
      return resultUrl*/
      var resultUrl = ""

      switch (this.userType){
        case "Admin" :
          resultUrl = `/${this.accountId}/dashboard/overallAdmin`;
          break;
        case "Manager" :
          resultUrl = `/${this.accountId}/dashboard/DroneStatusMap`;
          break;
        case "User" :
          resultUrl = `/${this.accountId}/eventControl/liveListControlUser`;
          break;
      }
      location.href = resultUrl
    },
    onSetDeviceLogCount(deviceLogCount){
      this.deviceLogCount = deviceLogCount;
    },
    onSetVodCount(vodCount){
      this.vodCount = vodCount;
    },
    onEventJoinWorkerCount(memberCount){
      this.memberCount = memberCount;
    },
    // 화면 분활 탭
    changeTab(value) {
      this.screenVisible = parseInt(value);
      this.handleFloatTab(this.screenVisible)
    },
    toggleSound(channelId){
      this.selectedSoundId = channelId
    },

    checkStreamId (data) {
      return {
        channelStreamId: data.assignedDeviceType === 'Drone' ? data.assignedDeviceLogId : data.assignedWorkerId,
        type: data.assignedDeviceType
      };
    }

  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.channelInterval);
    clearInterval(this.mapCountInterval);
  },
};
</script>

